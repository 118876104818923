<template>
  <div class="trainingWrap container">
    <header>
      <headerTop></headerTop>
      <navTab></navTab>
    </header>
    <div class="main-content">
        <div class="title">
          精品课程
        </div>
        <courseCatTree @updateCourseList='updateCourseList'></courseCatTree>
        <emptyData v-show='recordsData.length==0'></emptyData>
        <div class="jpkc-content-main"  v-show='recordsData.length>0'>
            <ul class="list-ul">
              <li v-for="(item,index) in recordsData" :key='index' @click='jumpCourseInfo(item)'>
                <div class="course-cover" v-show='!item.courseCover_url'>
                    <div class="course-content">
                      {{item.courseTitle}}
                    </div>
                  </div>
                  <div class="course-cover" v-show='item.courseCover_url'>
                    <img :src="item.courseCover_url" alt="">
                  </div>
                <div class="course-title">{{item.courseTitle}}</div>
              </li>
            </ul>
        </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import emptyData from '@/components/emptyData.vue'
import courseCatTree from '@/components/courseCatTree.vue'
import navTab from '@/components/navTab.vue'
import headerTop from '@/components/header.vue'
import footerCom from '@/components/footer.vue'
export default {
  name: '',
  components: {
    emptyData,
    footerCom,
    navTab,
    courseCatTree,
    headerTop
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      recordsData: [],
      courseCatId: 0,
      sortFields: {}
    }
  },
  methods: {
    updateCourseList (courseCatId, sortFields) {
      console.log(courseCatId)
      this.courseCatId = courseCatId
      this.sortFields = sortFields
      this.getRecordsData()
    },
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    jumpCourseInfo (item) {
      let courseId = item.id
      if (item.isMultiple == 'Y') {
        this.$router.push(`/childrenChaptersCourse/${courseId}`)
      } else {
        this.$router.push(`/overCourseInfo/${courseId}`)
      }
    },
    getRecordsData () {
      let reqOption = {
        'pageNo': 1,
        'pageSize': 999,
        'params': {'bizType': 'EXCELLENT'},
        'sortFields': []
      }
      if (this.courseCatId) {
        reqOption.params['courseCatId'] = this.courseCatId
      }
      if (this.sortFields.field == 'default') {
        reqOption.sortFields = []
      } else if (this.sortFields.field == 'time') {
        reqOption.sortFields = [{'field': 'create_time', 'direct': this.sortFields.direct ? this.sortFields.direct : 'ASC'}]
      }
      this.$axios.post(this.$apiUrl.queryCoursePage, reqOption).then(res => {
        console.log(res)
        let {records} = res.data.data
        this.recordsData = JSON.parse(JSON.stringify(records))
      })
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
  },
  mounted () {
    this.getRecordsData()
    this.getUserInfo()
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;
  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }
}
  .main-content {
    width: 1240px;
    min-height: 400px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding: 30px;
    padding-bottom: 188px;
    background-color: transparent;
    .title{
      font-size: 22px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #1C4ECC;
    }
  .jpkc-content-main {
    padding-top: 30px;
    .list-ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 25%;
        color: #000000;
        margin-bottom: 30px;
        .course-cover {
          cursor: pointer;
          width: 285px;
          height: 190px;
          font-size: 22px;
          font-family: STKaiti-Regular, STKaiti;
          img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
          .course-content {
            background: url("../../assets/tuanBg.png") no-repeat;
            background-size: 100% 100%;
             width: 285px;
            height: 190px;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            padding: 0px 10px;
            justify-content: center;
            align-content: center;
            color: #ffff;
          }
        }
        .course-title {
          cursor: pointer;
          padding-top: 22px;
          width: 285px;
          font-size: 20px;
          line-height: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2 ;
          -webkit-box-orient: vertical;
        }
        .course-title:hover{
            color: #25A3FF ;
          }
      }
    }
  }
  }
</style>
