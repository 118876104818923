<!--
* @Description:
-->
<template>
  <div class="navTabList">
    <div class="navTabItem">
      <div class='optionName'>课程</div>
      <ul>
        <li :class="{ activeTab: activeIndex1 == 0 }" @click="treeOneLevel()">
          全部
        </li>
        <li
          v-for="(item, index) in treeList"
          :key="index"
          @click="treeOneLevel(item)"
          :class="{ activeTab: activeIndex1 == item.id }"
        >
          {{ item.catName }}
        </li>
      </ul>
    </div>
    <div class="splitRow"></div>
    <div class="navTabItem">
      <div  class='optionName'>类型</div>
      <ul>
        <li :class="{ activeTab: activeIndex2 == 0 }" @click="treeSecondLevel()">
          全部
        </li>
        <li
          v-for="(item, index) in treeSecondList"
          :key="index"
          @click="treeSecondLevel(item)"
          :class="{ activeTab: activeIndex2 == item.id }"
        >
          {{ item.catName }}
        </li>
      </ul>
    </div>
    <div class="handleOrder">
      <div>排序</div>
      <div :class="{'activeHandle':sortType=='default'}" @click="setSortType('default')">默认</div>
      <div @click="setSortType('time')"  :class="{'activeHandle':sortType=='time'}">
        时间
        <span class="caret-wrapper">
          <i class="el-icon-caret-top" :class="{'activeIcon':timeSortType=='ASC'}"></i>
          <i class="el-icon-caret-bottom" :class="{'activeIcon':timeSortType=='DESC'}"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  watch: {},
  data () {
    return {
      activeIndex1: 0,
      activeIndex2: 0,
      treeList: [],
      treeSecondList: [],
      navId: '',
      sortType: 'default',
      timeSortType: ''
    }
  },
  methods: {
    getCatTree () {
      this.$axios.post(this.$apiUrl.queryCourseCatTree, {}).then((res) => {
        console.log(res)
        let resData = res.data
        if (resData.success) {
          this.treeList = resData.data
        } else {
          this.treeList = []
        }
        // this.recordsData = JSON.parse(JSON.stringify(records))
      })
    },
    treeOneLevel (item) {
      this.activeIndex2 = 0
      this.treeSecondList = []
      if (item) {
        this.activeIndex1 = item.id
        this.navId = item.id
        this.treeSecondList = item.children
        this.$emit('updateCourseList', item.id, {field: this.sortType, direct: this.timeSortType})
      } else {
        this.activeIndex1 = 0
        this.navId = 0
        this.$emit('updateCourseList', 0, {field: this.sortType, direct: this.timeSortType})
      }
    },
    treeSecondLevel (item) {
      if (item) {
        this.activeIndex2 = item.id
        this.navId = item.id
        this.$emit('updateCourseList', item.id, {field: this.sortType, direct: this.timeSortType})
      } else {
        this.activeIndex2 = 0
        this.navId = 0
        this.$emit('updateCourseList', this.activeIndex1, {field: this.sortType, direct: this.timeSortType})
      }
    },
    setSortType (type) {
      console.log(type)
      this.sortType = type
      if (type == 'time') {
        if (this.timeSortType == '') {
          this.timeSortType = 'ASC'
        } else
        if (this.timeSortType == 'ASC') {
          this.timeSortType = 'DESC'
        } else
        if (this.timeSortType == 'DESC') {
          this.timeSortType = ''
        }
        console.log(this.timeSortType)
      }
      this.$emit('updateCourseList', this.navId, {field: this.sortType, direct: this.timeSortType})
    }
  },
  mounted () {
    this.getCatTree()
  },
}
</script>

<style scoped lang="scss">
.navTabList {
  padding: 10px 35px;
  .optionName{
    line-height: 33px;
  }
  .navTabItem {
    display: flex;
    padding: 20px  0px;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    ul {
      display: flex;
      margin-left: 40px;
      li {
        padding: 4px 8px;
        font-size: 18px;
        font-weight: 400;
        color: #525455;
        cursor: pointer;
        margin-right: 24px;
      }
      .activeTab {
        background: #4774df;
        border-radius: 8px 8px 8px 8px;
        color: #fff;
      }
    }
  }
  .splitRow {
    height: 1px;
    opacity: 1;
    border: 1px solid #9dd5ff;
  }
  .handleOrder {
    margin-top: 10px;
    width: 1140px;
    background: #ffffff;
    box-shadow: 0px 6px 30px 1px rgba(0, 0, 0, 0.12);
    border-radius: 7px 7px 7px 7px;
    opacity: 1;
    display: flex;
    padding: 15px 60px;
    font-size: 16px;
    font-family: SimHei-Regular, SimHei;
    font-weight: 400;
    color: #000000;
    div {
      margin-right: 50px;
      cursor: pointer;
    }
    .activeHandle {
      color: #1c4ecc;
      >span{
        color: #000;
        .activeIcon{
        color: #1c4ecc;
      }
      }
    }
    .caret-wrapper {
      width: 18px;
      cursor: pointer;
      overflow: initial;
      position: relative;
      .el-icon-caret-top {
        position: absolute;
        top: -4px;
      }
      .el-icon-caret-bottom{
        position: absolute;
        bottom: -4px;
      }
    }
  }
}
</style>
